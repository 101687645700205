
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

























































.testimonial {
  position: relative;
  background-color: $c-promo-card;
  color: $white;
}

.testimonial-inner {
  position: relative;
  margin: auto;
  display: flex;

  @include mq($until: l) {
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
  }
}

.testimonial__picture {
  @include mq($until: l) {
    @include aspect-ratio(320, 205);

    width: 100%;
  }

  @include mq(l) {
    @include get-all-space;

    width: col(6);
  }
}

.testimonial__picture-inner {
  @include get-all-space;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.testimonial__content {
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @include mq($until: l) {
    margin: $spacing * 2 0;
  }
}

.testimonial__content-inner {
  @include mq(l) {
    width: col(6);
  }
}

.testimonial__text {
  @extend %fw-semi;
  @include fluid(
    font-size,
    (
      s: 24px,
      xxl: 40px,
    )
  );

  margin: 0;

  ::v-deep p {
    margin: 0;
  }

  @include mq(l) {
    padding-left: $spacing;
  }
}

.testimonial__text + .testimonial__author {
  margin-top: $spacing * 1.5;
}

.testimonial__author {
  @include fluid(
    font-size,
    (
      s: 16px,
      xxl: 18px,
    )
  );
}

.testimonial__author__name {
  @extend %fw-bold;
}
