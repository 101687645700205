
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































































































































































































// see extends.scss
@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
}

.net-speed {
  overflow: hidden;
  padding: $spacing * 2 0 $spacing * 10;
  color: $white;
  background: $c-promo-card;

  @include mq(l) {
    --side-width: 45%;

    overflow: visible;
    padding: $spacing * 7.5 0 $spacing * 15;
  }

  @include mq(xl) {
    --side-width: 55%;
  }
}

.net-speed__title {
  @extend %text-center;

  ::v-deep span {
    display: block;
  }

  @include mq(l) {
    @include visually-hidden;
  }
}

.net-speed-inner {
  position: relative;

  @include mq(l) {
    display: flex;
    align-items: flex-start;
  }
}

.net-speed__sections {
  position: relative;

  @include mq(xl) {
    display: flex;
    justify-content: space-between;

    &::before {
      content: '';
      position: absolute;
      top: auto;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0.4rem;
      height: 65%;
      transform: none;
      background: $c-bunting;
      border-radius: 1rem;
    }

    [class*='voo-app--business'] & {
      &::before {
        display: none;
      }
    }
  }
}

.net-speed__sections__link {
  @include center-x;

  top: calc(100% + 2rem);
  width: 100%;
  text-align: center;

  ::v-deep {
    span {
      display: initial !important;
    }
  }

  @include mq(l) {
    top: calc(100% + 6rem);
  }
}

.net-speed__side {
  --side-title-height: 40rem;

  display: none;

  @include mq(l) {
    position: sticky;
    top: calc(50% - calc(var(--side-title-height) * 0.5));
    display: block;
    flex-shrink: 0;
    width: var(--side-width);
  }
}

.net-speed__side__title-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--side-title-height);

  .is-ready & + & {
    position: absolute;
    top: 0;
  }
}

.net-speed__side__title,
.net-speed__section__title {
  @extend %fw-ultra;
  @extend %text-uppercase;
  @extend %text-center;

  margin: 0;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1;

  ::v-deep strong {
    @extend %fw-ultra;

    display: block;
    font-size: 2em;
  }

  @include mq(l) {
    margin-bottom: 2.5rem;
    font-size: 4.8rem;
    line-height: 5.7rem;
    transition: opacity 0.2s;

    ::v-deep strong {
      font-size: 1em;
    }
  }
}

.net-speed__side__subtitle,
.net-speed__section__subtitle {
  @extend %fw-semi;
  @extend %text-center;

  margin-bottom: 0;
  font-family: $ff-alt;
}

.net-speed__side__canvas,
.net-speed__section__canvas {
  @include center-xy;

  z-index: -1;
  width: 200%;
  height: 200%;
}

.net-speed__section {
  --margin-top: #{$spacing * 4};

  position: relative;

  & + & {
    margin-top: var(--margin-top);

    &::before {
      content: '';
      position: absolute;
      top: calc(var(--margin-top) * -0.5);
      left: 0;
      display: block;
      width: 100%;
      height: 0.4rem;
      background: $c-bunting;
      border-radius: 1rem;
      transform: translateY(-50%);
    }
  }

  &:first-child {
    .net-speed__section__title,
    .net-speed__section__subtitle {
      @extend %fw-medium;

      ::v-deep strong {
        @extend %fw-medium;
      }
    }
  }

  @include mq(xl) {
    --margin-top: 0;

    width: calc(50% - 75px);

    [class*='voo-app--business'] & {
      width: var(--side-width);
      margin-left: auto;
    }

    & + & {
      &::before {
        display: none;
      }
    }
  }
}

.net-speed__section__title-outer {
  position: relative;
  z-index: 1;
  padding: $spacing * 3 0;

  @include mq(xl) {
    padding: $spacing * 3 0 10rem;

    [class*='voo-app--business'] & {
      position: absolute;
      top: 0;
      right: 100%;
      width: 100%;
    }
  }
}

.net-speed__section__speeds {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-x: auto;
  margin: 0 0 $spacing * 2;
  padding: 0;
  gap: 0.8rem;
  list-style-type: none;
}

.net-speed__section__speed {
  padding: 1.5rem;
  border: 1px solid $c-anthracite-light;
  border-radius: 1rem;

  @include mq(xl) {
    padding: 2rem;
  }
}

.net-speed__section__speed__label {
  color: $c-blue-light-pale;
  font-size: 1.5rem;
}

.net-speed__section__speed__value {
  @extend %fw-semi;

  margin-top: $spacing * 0.25;
  font-family: $ff-alt;
  font-size: 2rem;
  line-height: 2.4rem;

  @include mq(l) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}

.net-speed__section__description {
  width: 100%;

  ::v-deep {
    ul {
      @extend %list-nostyle;

      margin-top: $spacing * 0.75;
    }

    li {
      display: flex;
      align-items: baseline;

      &::before {
        content: '';
        position: relative;
        top: 0.3rem;
        display: block;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: $spacing * 0.5;
        background-image: url('../../inc/svg/icons/checkok.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      & + & {
        margin-top: $spacing * 0.5;
      }
    }
  }
}
