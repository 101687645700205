
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































.map,
[class*='map--'] {
  position: relative;
  overflow: hidden;
  background-color: $c-white-dark;
}

.map__inner {
  position: relative;
}

.map__svg {
  display: flex;

  @include mq(m) {
    position: absolute;
    top: 0;
    right: 0;
    width: col(5.5);
    height: 100%;
  }

  @include mq(xl) {
    width: col(6.5);
  }
}

.map__content {
  padding: 0 $wrapper-spacing-s;

  @include mq(m) {
    padding: $spacing * 2 $wrapper-spacing-s 0;
  }

  @include mq(m) {
    width: col(6);
    padding: 0;
  }

  @include mq(l) {
    padding: $spacing * 3 0;
  }

  @include mq(xl) {
    width: col(5);
  }
}

.map__content__title {
  margin: 0 0 $spacing;

  strong {
    @extend %fw-black;
  }
}

.map__content__text {
  margin-bottom: 4rem;
}

.map__insert {
  margin-top: $spacing * 4;
  padding: 3.5rem;
  position: relative;
  border: 1px solid $c-pink-medium;
  border-radius: 8px;
}

.map__insert__label {
  position: absolute;
  top: 0;
  left: 2.5rem;
  transform: translateY(-50%);
  border-radius: 4px;
}

.map__insert__title {
  margin-bottom: $spacing * 0.5;
}
.map__insert__subtitle {
  margin-bottom: 2.5rem;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2rem;
}
